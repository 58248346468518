<template>
  <div class="main">

    <div class="content">
      <div ref="sidebar" class="sidebar mobile__hidden scroll">
        <addButton :props="{title: 'Добавить проект', type: 'project'}"/>
        <menuProject v-for="item in projectList" :key="item.id" :item="item"/>
      </div>
      <div ref="window" class="window scroll">
        <div class='menu__button'>
          <button @click="showMenu">
            <div><iconSvg name='arrow_left'/></div>
            <div>Проекты</div>
          </button>
        </div>
        <editor class='editor' v-if="$route.params.id" :key="$route.params.id"></editor>
        <div v-else class="main__title">DOBRO Пожаловать!</div>
      </div>
    </div>
  </div>
</template>

<script>
import {eventBus} from '../main';
import Editor from "@/components/Editor.vue";
import {mapGetters, mapActions} from "vuex"
import menuProject from '../components/menuProject.vue';
import AddButton from '../components/AddButton.vue';

export default {
  components: { 
    Editor,
    menuProject,
    AddButton, 
  },
  name: "MainPage",
  data() {
    return {
    };
  },
  props: {},
  computed: {
    ...mapGetters(['projectList', 'getUser', 'getUsers']),
  },
  methods: {
     ...mapActions(['getProject', 'fetchUser', 'fetchUsers']),
     showMenu(){
       if (this.$refs.window && this.$refs.sidebar) {
         this.$refs.window.classList.add('mobile__hidden');
         this.$refs.sidebar.classList.remove('mobile__hidden');
       }

     },
     showEditor(){
       if (this.$refs.window && this.$refs.sidebar) {
        this.$refs.sidebar.classList.add('mobile__hidden')
        this.$refs.window.classList.remove('mobile__hidden')
       }
     },
  },
  async mounted() {
    await this.getProject();
    await this.fetchUser();
    await this.fetchUsers();
    eventBus.$on("showWindow", () => {
       this.showEditor();
    });
  },
};
</script>



<style scoped>



.main {
  width: 100vw;
}

.content {
  display: flex;
  flex-direction: row;
}

.sidebar {
  background-color: var(--body-bg);
  width: 30%;
  padding-right: 30px;
}

.content,
.sidebar {
  height: 90vh;
}


.window {
  background-color: var(--main-window);
  width: 70%;
}

.window,
.sidebar {
  overflow: auto;
}

.main__title {
  margin-top: 60px;
  font-family: "Proxima", Arial, sans-serif;
  text-align: center;
  font-size: 32px;
}


.menu__button{

  display: none;
  
}
@media (max-width: 768px) {



.window {
  width: 100%
}
.sidebar {
  width: 100%;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;           /* Non-prefixed version, currently */
}

.mobile__hidden{
  display: none;
}

.menu__button{
  position: fixed;
  width: 100%;
  z-index: 40;
  display: block;
  height: 40px;
  background-color: var(--army);
  
} 

.menu__button button {
  height: 100%;
  background: none;
  border: none;
  font-size: 18px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--reverse);
}

.menu__button button:hover {
 color: var(--reverse-hover);
}

.menu__button button div {
  padding: 5px;
  margin: 0 auto;
}
}

</style>
