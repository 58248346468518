<template>
    <div class='permission__tool'>
        
        <h2> Настройка доступа </h2> 

        <div @click="$emit('close');clearAllPermissions()" class="close">
        <iconSvg  name="close"/>   
        </div>
        <div class='action__block'>

        </div>
        <div class="content">
                        <div class="list_perms scroll">
                            <span> Владелец: {{item.owner.first_name}} {{item.owner.last_name}} </span>
                        </div>
                        <div class="list_perms scroll">
                            <span> Редакторы: </span>
                        
                            <span 
                                class='item__user' 
                                v-for="userE in getUsersEditors" :key="'UE' + userE.id">
                                {{userE.first_name}} {{userE.last_name}} 
                                <span @click="setPermission(userE.id, field='user', action='deny_edit_user')" >&#10006;</span> 
                            </span>
                            <span 
                                class='item__group' v-for="groupE in getGroupsEditors" :key="'GE' + groupE.id">
                                {{groupE.name}} 
                                <span @click="setPermission(groupE.id, field='group', action='deny_edit_group')" >&#10006;</span> 
                            </span>
                        </div>
                        <div class="list_perms scroll">
                            <span> Читатели: </span>
                            <span 
                                class='item__user' v-for="userR in getUsersReaders" :key="'UR' + userR.id">
                                {{userR.first_name}} {{userR.last_name}} 
                                <span @click="setPermission(userR.id, field='user', action='deny_read_user')" >&#10006;</span> 
                            </span>
                            <span 
                                class='item__group' 
                                v-for="groupR in getGroupsReaders" :key="'GR' + groupR.id">
                                {{groupR.name}} 
                                <span @click="setPermission(groupR.id, field='group', action='deny_read_group')" >&#10006;</span> 
                            </span>
                        </div>

                <div class="res__list scroll">
                    <ul>

                        <div v-for="group in getGroups" :key="group.id"> 
                        <permission-group
                            :group="group"
                            :itemtype="itemtype"
                            :item="item"
                        />
                        </div>
                    </ul>
                    <ul>
                        <div v-for="user in getUsers" 
                        :key="user.id">
                        <permission-user 
                            :user="user" 
                            :itemtype="itemtype" 
                            :item="item" 
                        /> 
                        </div>
                    </ul>

                </div>
               
        </div>
    </div>
</template>


<script>
import iconSvg from './iconSvg.vue'
import {mapActions, mapGetters} from "vuex"
import PermissionUser from './PermissionUser.vue';
import PermissionGroup from './PermissionGroup.vue';
export default {
  components: { 
      iconSvg,
      PermissionUser,
      PermissionGroup,
  },
  data(){
      return {
      }
  },
  name: "PermissionTool",
  props: {
      item: Object,
      itemtype: String,
    },

    methods: {
        ...mapActions(["clearAllPermissions", 'fetchAllow']),
        ...mapActions([
            'fetchGroups', 
            'fetchUsers', 
            'fetchUsersEditors',
            'fetchUsersReaders',
            'fetchGroupsEditors',
            'fetchGroupsReaders', 
        ]),         
        setPermission(recepient_list=1, field='group', action='deny_edit_user') {
            const obj = {
                type: this.itemtype,
                id: this.item.id,
                recepient_list: [recepient_list],
                action: action,
                field: `${field}s`,
            }
            
            this.fetchAllow(obj);
        },
    },
    mounted() {
        this.fetchUsersEditors({type: this.itemtype, id: this.item.id});
        this.fetchUsersReaders({type: this.itemtype, id: this.item.id});
        this.fetchGroupsEditors({type: this.itemtype, id: this.item.id});
        this.fetchGroupsReaders({type: this.itemtype, id: this.item.id});
        this.fetchUsers({type: this.itemtype, id: this.item.id});
        this.fetchGroups({type: this.itemtype, id: this.item.id});
    },
    computed: {
      ...mapGetters([
          'getUsers', 
          'getGroups', 
          'getUsersEditors',
          'getUsersReaders',
          'getGroupsEditors',
          'getGroupsReaders',
          ]),
    },
}
</script>


<style scoped>

.permission__tool {
    box-shadow: 0em 0em 20px 6px rgb(0 0 0 / 50%);
    width: 768px;
    height: 670px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -384px;
    margin-top: -300px;
    background-color: var(--secondary);
    color: var(--reverse-hover);
    z-index: 2000;
    cursor: auto;
    animation: FadeBlock 0.3s;
    transition: 1s;
    max-height: 100%;
    border-radius: 20px;
    }

@keyframes FadeBlock {
  0% {
    transform: translate(0, -1000px);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

.permission__tool h2, h3{
    text-align: center;
}

.close {
    position: absolute;
    top: 0;
    right: 0;
    margin: 30px;
    cursor: pointer;
    transform: scale(1.5);
    transition: 0.1s;
    color: var(--reverse);
    
}

.close:hover {
    color: var(--reverse-hover);
    transform: scale(1.6);
}


.res__list {
    width:100%;
    height:350px;
    overflow: auto; 
    max-height: 100%;
}

.action__block{
    justify-content: space-around;
    display: flex;
}

.content{
    padding: 20px;
}

button {
    background-color: var(--primary);
    color: var(--light-hover);
    border: 0;
    width: 100px;
    height: 30px;
    margin: 10px;
}

button:hover {
    background-color: var(--primary-hover);
}


ul {
    padding: 0px;
    overflow: auto;
    background-color: var(--secondary-hover);
}

.list_perms {
    display: flex;
    height: 60px;
    overflow: auto;
    margin-bottom: 10px;
    
}

.list_perms span{
    height: 30px;
    display: flex;
    align-items: center;
}

.item__group, .item__user {     
    color: var(--light-hover);
    display: flex;
    align-items: center;
    border-radius: 15px;
    padding: 8px; 
    white-space: nowrap;
    animation: showBlock 0.3s linear forwards;
    margin-left: 5px;
    margin-right: 5px;
}

.item__group span, .item__user span{     
    display: flex;
    align-items: center;
    padding: 3px;
}

@keyframes showBlock {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.item__group {     
    background-color: var(--success);
    }

 .item__user {     
    background-color: var(--primary-hover);
    }

.item__group span, .item__user span {
    cursor: pointer;
    margin: 0 auto;
    transition: 0.2s;
}

.item__group span:hover, .item__user span:hover {
    color: var(--warning-hover);
}


@media (max-width: 768px) {

h2{
    font-size: 18px;
}
.permission__tool{

    width: 100%;
    height: 100%;
    position: fixed;
    margin: 0 auto;
    top:0;
    left:0;
    background-color: var(--secondary);
    color: var(--reverse-hover);
    z-index: 2000;
    cursor: auto;
    animation: FadeBlock 0.3s;
    transition: 1s;
    border-radius: 0;
    }
}
</style>