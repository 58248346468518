<template>
<ul>
  <PermissionTool :item="item" :itemtype='itemtype' v-click-outside="closePermissionTool" @close="closePermissionTool" v-if="permissiontool"></PermissionTool>
  <li class="document"  v-if="item" @mouseleave="hide()">
   <div class="project__head" :class="{'project__head_current': $route.params.id == item.id, 'hovered': tools}" v-if="!edit" @contextmenu.prevent="openTools()">
        <div @click="showEditor()">
          <router-link  class="project__name"   :to="{path: `/document/${item.id}`}">
            <div class="project__icon">
              <iconSvg name="document"/>
            </div>
            <div>
              {{item.title }}
            </div>
          </router-link>
        </div>

         <div class="project__tools" v-if="availableTools">
          <div class="project__menu" @click="openTools()">
            <iconSvg name="more"/>
          </div>
          <tools 
            v-if="tools" 
            v-click-outside="hide" 
            :availableTools="availableTools" 
            :item="item"
            :itemtype="itemtype"
            :closeTools="closeTools"
            @permissions="openPermissionTool(item.id, itemtype)" 
            @delete="deleteOneDocument(item.id)" 
            @rename="rename(item.name)"
            @move="moveObject({item, itemtype})"
            />
        </div>
    </div>

      <!-- для переименования -->
      <changeNameInput
        v-else
        v-click-outside="hideInput"
        :callback="changeDocumentName"
        :itemName="item.title"
        :item="item"
        :callClose="hideInput"
      />
  </li>
  </ul>
</template>

<script>
import {eventBus} from '../main';
import PermissionTool from './PermissionTool.vue';
import ClickOutside from 'v-click-outside'
import {mapActions, mapGetters} from "vuex"
import ChangeNameInput from './ChangeNameInput.vue';
import Tools from './Tools.vue';
export default {
    name: "menuDocument",
    data: () => ({
        edit: false,
        name: "",
        tools: false,
        permissiontool: false,
        itemtype: 'document',
    }),
    props: {
        item: Object,
        parent_id: Number,
        callbackShow: Function,
    },
    components: {
        Tools,
        PermissionTool,
        ChangeNameInput,
    },
    directives: {
        ClickOutside: ClickOutside.directive
    },
    computed: {
       ...mapGetters([
         'getUser',
       ]),
      availableTools() {
        return ['rename', 'delete', 'move', 'permission'];
      }
    },
    methods: {
        ...mapActions([
            'deleteDocument', 
            'changeDocument',
            'getProject',
            'getProjectSections',
            'moveObject',
        ]),
        hide() {
            this.tools = false
        },
        showEditor(){
          if (!this.tools) {
          eventBus.$emit('showWindow');
          }
        },
        hideInput() {
            this.edit = false;
        },
        rename(text) {
            this.edit = true;
            this.name = text
        },
        openTools() {
          this.tools = true;
        },
        closeTools(){
          this.tools = false;
        },
        openPermissionTool(){
          this.permissiontool = true;
        },
        closePermissionTool(){
          this.permissiontool = false;
        },
        async changeDocumentName(name,id) {
            if(!name || !id) return;
            let form_data = new FormData();
            form_data.append("title", name)
            await this.changeDocument({
              data: form_data, 
              id: id, 
              proj_id: this.item.section.project
              }).then(() => {
                this.hide()
            });
        },
         async deleteOneDocument(id) {
            if(!id) return
            const props = {id: id, proj_id: this.item.section.project}
            await this.deleteDocument(props);
          
           
        }
    },
    mounted() {
      if(this.$route.params.id && this.$route.params.id == this.item.id) {
        this.callbackShow()
      }
    },
};
</script>

<style scoped>
ul {
    padding-left: 30px;
}
li > ul {
    border-left: 1px solid var(--reverse-hover);
}
li {
  font-size: 18px;
}

li > ul > li {
  font-size: 16px;
  /* cursor: pointer; */
}

li > ul > li > ul > li {
  font-size: 14px;
  /* cursor: pointer; */
}

li {
  list-style-type: none;
}

.document {
  cursor: pointer;
}

.project__head {
  padding: 5px;
  padding-left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: .3s;
  background-color: transparent;
}

.project__head:hover {
  background-color: var(--section-hover);
}

.project__head.hovered {
  background-color:var(--section-hover);
}

.project__remove {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .3s;
  cursor: pointer;
  opacity: 0.4;
}

.project__icon {
  margin-right: 10px;
}

.project__remove:hover {
  opacity: 1;
}

.project__head:hover .project__tools {
  opacity: 1;
}

.project__head.hovered .project__tools {
  opacity: 1;
}

.project__tools  {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  opacity: 0;
}


.project__name {
  width: 100%;
  display: flex;
}

.project__tools * {
  margin-left: 10px;
  flex-shrink: 0;
}

.project .project__head:hover  .project__menu {
  opacity: .7;
}

.project__tools > .project__menu:hover {
  opacity: 1;
  color: var(--reverse-hover);
} 

.project .project__head.hovered .project__menu {
  opacity: 1;
}

.project__tools:first-child {
  margin-left: 0;
}

.project__rename {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .3s;
  cursor: pointer;
  opacity: 0.4;
}

.project__rename:hover {
  opacity: 1;
}

.project__head_current .project__name {
    pointer-events: none;
    color: var(--selected);
}

.add-button__input-confirm {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  flex-shrink: 0;
  border: 0;
  display: block;
  background-color: var(--success);
  color: var(--light);
  cursor: pointer;
  border-radius: 5px;
}

.add-button__input-confirm:hover{
  background-color: var(--success-hover);
  color: var(--light-hover);
}

.add-button__input-container {
  z-index: 2;
  padding: 1px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  background-color: var(--light);
  display: flex;
  border-radius: 5px;
}

.add-button__input {
  margin-right: 1px;
  outline: none;
  border: 0px solid var(--secondary);
  height: 100%;
  width: 100%;
  min-height: 25px;
  display: block;
  color: var(--dark);
  padding-left: 15px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
</style>