import axios from 'axios';

export default ({
    state: {
        project: [],
        projectSections: []
    },
    getters: {
        projectList(state) {
            return state.project
        },
        projectSectionsList(state) {
            return state.projectSections
        }
    },
    mutations: {
        updateProject(state, project) {
            state.project = project;
        },
        addNewProject(state, project) {
            state.project.push(project);
        },
        removeProject(state, id) {
            state.project = state.project.filter(obj => obj.id !== id);
        },
        changeOneProject(state, project) {
            const item = state.project.findIndex(obj => obj.id === project.id);
            state.project[item] = project
        },
        updateProjectSection(state, project) {
            state.projectSections = project;
        },
    },
    actions: {
        getProject(ctx) {
            axios.get(`/api/project/`, {})
                .then((response) => {
                    ctx.commit('updateProject', response.data);
                })
                .catch((err) => {
                    console.log(err);
                })
        },

        getProjectSections(ctx, id) {
            axios.get(`/api/project/${id}/`, {})
            .then((response) => {
                ctx.commit('updateProjectSection', response.data);
            })
            .catch((err) => {
                console.log(err);
            });
        },

        addProject(ctx, data) {
            axios.post(`/api/project/`, data, {})
                .then((response) => {
                    ctx.commit('addNewProject', response.data);
                })
                .catch((err) => {
                    console.log(err);
                })
        },

        deleteProject(ctx, id) {
            axios.delete(`/api/project/${id}/`, {})
                .then((response) => {
                    if(response.status === 204) {
                        ctx.commit('removeProject', id);   
                        this._vm.$toasted.show('проект удален').goAway(2000);       
                    }  
                })
                .catch((err) => {
                    if (err.response.data.detail) {
                        this._vm.$toasted.error(err.response.data.detail).goAway(2500);
                    }
                    else {
                        console.log(err);
                    }
                })

        },
        
        changeProject(ctx, props) {
            axios.patch(`/api/project/${props.id}/`, props.data, {})
            .then((response) => {
                ctx.commit('changeOneProject', response.data);
                ctx.dispatch('getProject')
            })
            .catch((err) => {
                console.log(err);
            })
        },
    },
})