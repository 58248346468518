<template >
  <div class="add-button" v-click-outside="hide">
    <div class="add-button__head" @click="open = true" v-if="!open">
      <div class="add-button__icon">
        <iconSvg name="plus" />
      </div>
      <div class="add-button__title">
        {{ props.title }}
      </div>
    </div>

    <changeNameInput v-if="open"
      :callback="addNewProject" 
      :callClose="hide" />
  </div>
</template>

<script>
import ClickOutside from "v-click-outside";
import { mapActions } from "vuex";
import ChangeNameInput from "./ChangeNameInput.vue";

export default {
  components: { ChangeNameInput },
  props: {
    props: Object,
  },
  data: () => ({
    name: "",
    open: false,
  }),
  directives: {
    ClickOutside: ClickOutside.directive,
  },
  computed: {},
  methods: {
    ...mapActions(["addProject", "getProject"]),
    hide() {
      this.open = false;
    },
    async addNewProject(name) {
      if (!name) return;
      let form_data = new FormData();
      form_data.append("name", name);
      await this.addProject(form_data);
      this.hide();
    },
  },
};
</script>

<style scoped>
.add-button {
  margin-top: 15px;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  margin-left: 30px;
  opacity: 0.6;
  transition: 0.3s;
}

.add-button:hover {
  opacity: 1;
}

.add-button__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.add-button__head {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
</style>