<template>
    <div class='permission__action'>
        <div @click="$emit('close')" class="close">
            <iconSvg name="close"/>
        </div>
        <div> 
            <div v-if="user">
                <h3> {{user.last_name}} {{user.first_name}}</h3>
            </div>
            <div v-if="group">
                <h3>Группа {{group.name}}</h3>
            </div>
            <div class="content">                
                <form  v-on:submit.prevent="setPermission">
                    <select v-model="permissionAction" size="4">
                        <option disabled value="">Выберите права доступа </option>
                        <option value="allow_read">Читатель</option>
                        <option value="allow_edit">Редактор</option>
                        <option value="deny_read">Запретить просмотр</option>
                        <option value="deny_edit">Запретить редактирование</option>
                    </select>

                    
                    <button>{{buttonText}}</button>
                </form>          
            </div>
        </div>
    </div>
</template>

<script>

import { mapActions } from "vuex";
export default {
  
  data(){
      return {
          show_list: false,
          permissionAction: '',
      }
  },
  name: "PermissionToolAction",
  props: {
      group_or_user: String,
      group: Object,
      user: Object,
      itemtype: String,
      item: Object,
  },
    methods: {
        ...mapActions(["fetchAllow"]),
        setPermission(){
            if (this.permissionAction){             
                this.fetchAllow({
                    type: this.itemtype,
                    id: this.item.id,
                    recepient_list: this.recepient_list,
                    action: this.action,
                    field: `${this.group_or_user}s`,
                });
            }
            this.$emit('close');
        },
    },
    mounted() {},
    computed: {

        buttonText: function () {
            if (this.permissionAction){
                return 'Подтвердить';
            }
            return 'Закрыть'; 
        },
        action: function () {
            return `${this.permissionAction}_${this.group_or_user}`
        },
        recepient_list: function () {
            if (this.user){
                return [this.user.id]
            }
            return [this.group.id]
        },
    },
}
</script>


<style scoped>
.permission__action{
    box-shadow: 0em 0em 20px 6px rgb(0 0 0 / 50%);
    width: 500px;
    height: 390px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -250px;
    margin-top: -200px;
    background-color: var(--secondary);
    color: var(--reverse-hover);
    z-index: 3000;
    border-radius: 20px;
}

.permission__action h2, h3{
 text-align: center;
}

.close{
    position: absolute;
    top: 0;
    right: 0;
    margin: 23px;
    cursor: pointer;
    transform: scale(1.5);
    transition: 0.1s;
    color: var(--reverse);
    
}

.close:hover{
    color: var(--reverse-hover);
    transform: scale(1.6);
}

.action, .recepients{
    display: flex;
}

.action div, .recepients div{
    padding: 10px;
}

.action__block{
    justify-content: space-around;
    display: flex;
}

.content{
    width: 100%;
    margin: 0 auto;
    
}

button {
    background-color: var(--primary);
    color: var(--light-hover);
    border: 0;
    width: 100%;
    height: 50px;
    cursor: pointer;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}
button:hover {
    background-color: var(--primary-hover);

}



li:hover {
    list-style-type: none;
    background-color: var(--secondary);
}

.user__item {
    display: flex;
    align-items: center;
}
img {
    height: 30px;
    width:  30px;
    border-radius: 50%;
}

.user__email {
    color: var(--body-text);
    font-size: 14px;
}

ul {
    padding: 0px;
    overflow: auto;
    background-color: var(--secondary-hover);
}

select {
    width: 100%;
    overflow: auto;
    border: none;
    outline: none;
    text-align: center;
    height: 300px;
    background-color: var(--secondary-hover);
}

option {
    padding: 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: var(--secondary-hover);
    color: var(--reverse);
    transition: 0.2s;
}

option:hover {
    color: var(--reverse-hover);
}

@media (max-width: 768px) {

button {
    border-radius: 0;
}

h2{
    font-size: 18px;
}

select{
    height: 390px;
}

option{
    padding: 30px;
    font-size: 16px;
    cursor: pointer;
}

.permission__action {
    width: 100%;
    height: 100%;
    position: fixed;
    margin: 0 auto;
    top:0;
    left:0;
    background-color: var(--secondary);
    color: var(--reverse-hover);
    z-index: 2000;
    cursor: auto;
    animation: FadeBlock 0.3s;
    transition: 1s;
    border-radius: 0;
    }

}
</style>