import Vue from 'vue'
import Vuex from 'vuex'

import section from './modules/section'
import project from './modules/project'
import document from './modules/document'
import auth from './modules/auth'
import user from './modules/user'
import theme from './modules/theme'
import buffer from './modules/buffer'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        theme,
        auth,
        section,
        project,
        document,
        user,
        buffer,
    }
})