export default ({
    state: {
       theme: localStorage.getItem('theme') || 'dark',

    },

    getters: {
        theme: (state) => state.theme,
    },

    mutations: {
        setTheme(state, theme){
            state.theme = theme;
            localStorage.setItem('theme', theme);
            var root = document.querySelector(':root');
            root.classList.remove('dark');
            root.classList.remove('light');
            root.classList.add(theme);
        },
    },

    actions: {
        setTheme(ctx, theme='dark'){
            ctx.commit('setTheme', theme);
        },

    },
})