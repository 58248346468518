<template>
 
  <div class="editor-wrap">
    <consumers :room="$route.params.id"/>
    <div class='editor-title'>
      <h2>
      {{detailDocument.title}}
      </h2>
      </div>
    <div id="editorjs"></div>
    <button v-if="inEdit" class='button__cancel' @click="cancel()">Выйти из редактирования</button>
    <button v-if="!readOnly && !inEdit" class='button__edit' @click="edit()">Редактировать</button>
    <button v-if="inEdit && detailDocument.editor_json" class='button__save' @click="save()">Сохранить</button>
    <div class="document__author" v-if="documentAuthor && detailDocument.editor_json"> <span>{{documentAuthor}} &nbsp;&nbsp; {{documentDate}}</span> </div>
  </div>
</template>

<script>
import Consumers from "./Consumers.vue";
import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";
import List from "@editorjs/list";
import ImageTool from "@editorjs/image";
import Quote from "@editorjs/quote";
import RawTool from "@editorjs/raw";
import Checklist from "@editorjs/checklist";
import Table from '@editorjs/table';
import {mapGetters, mapActions} from "vuex"
export default {
  name: "Editor",

  data() {
    return {
      inEdit: false,
      errorDocument: {
        time: 1629209706671,
        blocks: [{ type: "header", data: { text: "Документ не найден. Или у вас нет прав доступа для просмотра", level: 4 } }],
        version: "2.20.0",
    },
    read: false,
    timeoutID: undefined
    };
  },

  watch: {
    inEdit: function () {
      window.editor.isReady
      .then(() =>
        { 
          // нужно попробовать использовать метод window.editor.readOnly.toggle()
          window.editor.destroy();
          this.editor();
          this.open();
          })
      }
  },

  components: {
    Consumers,
  },

  computed: {
    ...mapGetters(['detailDocument', 'Authorization', 'getUser', 'theme', 'getUsers']),
    readOnly() {
      return !(
       ((this.detailDocument && this.detailDocument.owner &&
       (this.detailDocument.owner.id === this.getUser.id
     || this.detailDocument.group_is_editor > 0 
     || this.detailDocument.user_is_editor > 0 
     || this.getUser.is_superuser))));
    },
    documentDate() {
      var monthNames = [
      "Января", "Февраля", "Марта",
      "Апреля", "Мая", "Июня", "Июля",
      "Августа", "Сентября", "Октября",
      "Ноября", "Декабря"
      ];
      let dates = ''
      if (this.detailDocument.created) {
        let created = new Date(this.detailDocument.created);
        dates += `Создан: ${created.getDate()} ${monthNames[created.getMonth()]} ${created.getFullYear()}; `
      }
      if (this.detailDocument.updated) {
        let updated = new Date(this.detailDocument.updated);
        dates += `Изменен: ${updated.getDate()} ${monthNames[updated.getMonth()]} ${updated.getFullYear()};`    
      }
      return dates

    },
    documentAuthor() {
        if (this.detailDocument.owner) {
          for (let i in this.getUsers) {
            if (this.getUsers[i].id === this.detailDocument.owner.id) {
              return `Автор: ${this.getUsers[i].first_name} ${this.getUsers[i].last_name}; `;
            }
          }
        }
      
      return ''
    },
    defaultColor(){
      if (this.theme === 'dark')
        return '#FFFFFF'
      return '#000000'
    }
  },

  methods: {
    ...mapActions(['getDetailDocument', 'changeDocument', 'fetchUser']),
    edit() {
      this.inEdit = true;
    },
    cancel() {
      this.inEdit = false;
      this.getDetailDocument(this.$route.params.id);
    },
    save() {
      window.editor.save()
        .then((outputData) => {
          let form_data = new FormData();
          form_data.append("editor_json", JSON.stringify(outputData))
          this.changeDocument({
              'data': form_data,
              'id': this.detailDocument.id,
              'proj_id': this.detailDocument.section.project,
            })
        })
        .catch((error) => {
          this.$toasted.show(`Ошибка сохранения: ${error}`).goAway(1000);
        });
        this.$toasted.show('Сохранено').goAway(1500);
    },
    async open() {
        await window.editor.isReady
        if(window.editor) {
          let document = this.detailDocument.editor_json;
          if (!document){
            document = this.errorDocument;
          }
          if (window.editor) {
            window.editor.render(document);  
          }
        }
    },
    
     editor: function () {
      const ColorPlugin = require('editorjs-text-color-plugin');
      const LinkTool = require('@editorjs/link');
      const Embed = require('@editorjs/embed');
      const InlineCode = require('@editorjs/inline-code');
      const Warning = require('@editorjs/warning');
      const CodeTool = require('@editorjs/code');
      window.editor =  new EditorJS({

  i18n: {
    messages: {

      ui: {
        "blockTunes": {
          "toggler": {
            "Click to tune": "Нажмите, чтобы настроить",
            "or drag to move": "или перетащите",
          },
        },
        "inlineToolbar": {
          "converter": {
            "Convert to": "Конвертировать в"
          }
        },
        "toolbar": {
          "toolbox": {
            "Add": "Добавить"
          }
        }
      },

      toolNames: {
        "Color": 'Цвет',
        "Marker": 'Выделить',
        "Text": 'Текст',
        "linkTool": 'Ссылка',
        "raw": 'Сырой текст',
        "Image": 'Изображение',
        "Heading": "Заголовок",
        "List": "Список",
        "Warning": "Примечание",
        "Checklist": "Чеклист",
        "Quote": "Цитата",
        "Code": "Код",
        "Delimiter": "Разделитель",
        "Raw HTML": "HTML-фрагмент",
        "Table": "Таблица",
        "Link": "Ссылка",
        "Bold": "Полужирный",
        "Italic": "Курсив",
        "InlineCode": "Моноширинный",
      },
      tools: {
        "warning": { 
          "Title": "Название",
          "Message": "Сообщение",
        },
        "link": {
          "Add a link": "Вставьте ссылку"
        },
        "stub": {
          'The block can not be displayed correctly.': 'Блок не может быть отображен'
        }
      },
    }
  },
        holderId: "editorjs",
        readOnly: !this.inEdit,

        tools: {
          header: {
            class: Header,
            inlineToolbar: true,
          },
          code: CodeTool,
          warning: Warning,
          inlineCode: {
            class: InlineCode,
            inlineToolbar: true,
          },
          list: {
            class: List,
            inlineToolbar: true,
          },
          quote: {
            class: Quote,
            inlineToolbar: true,
            config: {
              quotePlaceholder: 'Введите цитату',
              captionPlaceholder: 'Автор цитаты',
            },
          },
          embed: {
            class: Embed,
            inlineToolbar: true,
             config: {
              services: {
                youtube: true,
                coub: true,
              }
            }
          },
          linkTool: {
            class: LinkTool,
            inlineToolbar: true,
            config: {
              endpoint: 'https://api.dobroagency.ru/api/link/', // Your backend endpoint for url data fetching
            },
          },
          table: {
            class: Table,
            inlineToolbar: true,
            config: {
              rows: 2,
              cols: 3,
            },
          },
          raw: {
            class: RawTool,
            inlineToolbar: true,
          },
          checklist: {
            class: Checklist,
            inlineToolbar: true,
          },
          image: {
            class: ImageTool,
            config: {
              captionPlaceholder: 'Подпись к картинке',
              endpoints: {
                byFile: "https://api.dobroagency.ru/api/image/", // Your backend file uploader endpoint
                byUrl: "https://api.dobroagency.ru/api/link/", // Your endpoint that provides uploading by Url
              },
              additionalRequestHeaders: {
                  'Authorization': this.Authorization,
              }
            },
          },
           Color: {
            class: ColorPlugin, // if load from CDN, please try: window.ColorPlugin
            inlineToolbar: true,
            config: {
              colorCollections: [this.defaultColor,
               '#db0000', // красный
               '#EC7878', // розовый
               '#b930d1', // малиновый
               '#7641d3', // фиолетовый
               '#495ed1', // серо-синий
               '#0070FF', // синий
               '#03A9F4', // голубой
               '#4CAF50', // зеленый 
               ],
              defaultColor: this.defaultColor,
              type: 'text', 
            }     
          },
          Marker: {
            class: ColorPlugin, // if load from CDN, please try: window.ColorPlugin
            inlineToolbar: true,
            config: {
              colorCollections: ['transparent', '#db0000','#EC7878','#9C27B0','#673AB7','#3F51B5','#0070FF','#03A9F4','#00BCD4','#4CAF50','#8BC34A','#6d7425'],
              defaultColor: '#FFBF00',
              type: 'marker', 
            }       
          },
        },
      });
    },
  },

  async mounted() {
    this.fetchUser();
    if(this.$route.params.id) {
      await this.getDetailDocument(this.$route.params.id)
        this.editor();
        this.open();

    }
    window.addEventListener("beforeunload", (e) => { 
        if(this.inEdit) {
          this.save()
        }
        e.preventDefault()
    });
  },

  beforeDestroy() {
    if(this.inEdit) {
      this.save()
    }
  },

};
</script>

<style>

.tc-table{
 border-left: 1px solid var(--color-border) !important;
}

.tc-row {
background: var(--table-background);
}

.editor-wrap .ce-block__content {
  max-width: 90%;
  min-height: 100%;
}

.editor-wrap .ce-toolbar__content {
  max-width: 90%;
}

.editor-wrap {
  margin-top: 20px;
  height: 90vh;
  width: 100%;
  color: var(--body-text);
}

.editor-title h2 {
  text-align: center;
  padding: 15px 0px 25px 0px;
  border-bottom: 1px solid;
  min-height: 70px;
}

.ce-inline-toolbar {
  color: #000;
}

.ce-rawtool__textarea {
 overscroll-behavior: auto !important;
}

.ce-code__textarea {
  background-color: var(--secondary) !important;
  color: var(--reverse) !important;
  border-color: var(--body-bg) !important;
  border-radius: 0px;
}

.ce-code__textarea::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.ce-code__textarea::-webkit-scrollbar-track {
  background: var(--secondary-hover);
}
.ce-code__textarea::-webkit-scrollbar-thumb {
  background-color: var(--secondary);
  border: 2px solid var(--secondary-hover); 
}


font[color='#FFFFFF'], font[color='#000000'] {
    color: var(--body-text);
}
.color-sign > button, .color-sign button[data-color="#FFFFFF"] {
  background-color: var(--body-text) !important;
}

#editorjs {
  min-height: 80%;
}

.button__save, .button__edit, .button__cancel  {
  background-color: var(--success);
  width: 150px;
  height: 40px;
  border: 0;
  border-radius: 10px;
  color: var(--light-hover);
  font-size: 16px;
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 20px;
  z-index: 50;
  transition: 0.2s;
  cursor: pointer;
}

.button__save:hover, 
.button__edit:hover, 
.button__cancel:hover{
  background-color: var(--success-hover);
}

.button__cancel {
  right: 180px;
  background-color: var(--grey);
  transition: 0.2s;
}

.button__cancel:hover {
  background-color: var(--dark);
}

.document__author {
  border-top: 1px solid;
  position: relative;
  margin: 0;
  bottom: 0;
  padding: 40px;
  font-size: 14px;
}

.document__author span {
    color: var(--body-text);
    padding: 5px 15px;
}

.codex-editor{
  min-height: 100%;
}

@media (max-width: 768px) {

.editor-wrap{
  margin-top: 40px;

  }
  .button__save {
    background: none;
      top: 10vh;
      right: 0;
      margin: 0;
      margin-right: 20px;
      color: var(--reverse)
  }
  .button__save:hover {
    background: none;
    color: var(--reverse-hover);
  }
}
</style>