import axios from 'axios';
export default ({
    state: {
        documents: [],
        detailDocument: {}
    },
    getters: {
        detailDocument(state) {
            return state.detailDocument
        }
    },
    mutations: {

        addNewDocument(state, documents) {
            state.documents.push(documents);
        },

        removeDocument(state, id) {
            state.documents = state.documents.filter(obj => obj.id !== id); 
        },

        updateDetailDocument(state, document) {
            state.detailDocument = document
        }

    },
    actions: {
        addDocument(ctx, props) {
            axios.post(`/api/document/`, props.data, {})
                .then((response) => {
                    ctx.commit('addNewDocument', response.data);
                    ctx.dispatch('getProjectSections', props.proj_id);
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        deleteDocument(ctx, props) {
            axios.delete(`/api/document/${props.id}/`, {})
                .then((response) => {
                    if(response.status === 204) {
                        ctx.commit('removeDocument', props.id);
                        ctx.dispatch('getProjectSections', props.proj_id);    
                        this._vm.$toasted.show('документ удален').goAway(2000);       
                    }  
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        changeDocument(ctx, props) {
            axios.patch(`/api/document/${props.id}/`, props.data, {})
                .then(() => {
                    ctx.dispatch('getProjectSections', props.proj_id);
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        moveDocument(ctx, props) {
            axios.patch(`/api/document/${props.id}/`, props.data, {})
                .then(() => {
                    ctx.dispatch('getProjectSections', props.new_prj);
                    this._vm.$toasted.success('Документ перемещен').goAway(2500);
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        getDetailDocument(ctx, id) {
            axios.get(`/api/document/${id}/`, {})
                .then((response) => {
                    ctx.commit('updateDetailDocument', response.data);
                    if (ctx.getters.projectSectionsList.id !== response.data.section.project) {
                        ctx.dispatch('getProjectSections', response.data.section.project);
                    }
                    
                })
                .catch((err) => {
                    console.log(err.response.data.detail);
                    this._vm.$toasted.error(err.response.data.detail).goAway(1500);
                });
        },
        
    },
})