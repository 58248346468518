import Vue from 'vue'
import App from './App.vue'
import router from './router';
import store from './store'
import axios from 'axios';

Vue.config.productionTip = false
import iconSvg from '@/components/iconSvg.vue'
Vue.component('iconSvg', iconSvg);

axios.defaults.baseURL = process.env.VUE_APP_BACKEND_HOST;
axios.interceptors.request.use(function (request) {
  // установка headers для каждого запроса
  request.headers.authorization = store.getters.Authorization;
  return request;
}, function (error) {
  return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
    if(error.response.status === 401) {
      // если получили 401 ошибку то перекидывает на авторизацию
      store.dispatch('jwtRemove');
      let path = location.pathname.substr(1);
      if (path !== 'login/'){
        router.push({path: `/login/?next=${path}`});
      }
    }
  return Promise.reject(error);
});
export const eventBus = new Vue()
import Toasted from 'vue-toasted';
Vue.use(Toasted)
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
