
export default ({
    state: {
        buffer: '',
    },
    getters: {

        buffer: (state) => state.buffer,
    },
    mutations: {

        setBuffer(state, obj) {
            state.buffer = obj;
        },

        clearBuffer(state) {
            state.buffer = '';
        },
    },
    actions: {
        moveObject(ctx, obj) {
            this._vm.$toasted.success('Выберите в дереве куда вставить объект').goAway(2500);
            ctx.commit('setBuffer', obj);
        },
        pasteObject(ctx, obj) {
            let buffer = this.getters.buffer;
            let id = buffer.item.id
            const data = new FormData()
            if (obj.itemtype === 'project') {
                if (buffer.itemtype === 'section') {
                    let new_prj = obj.item.id
                    data.append('project', new_prj)
                    data.append('parent', '')
                    ctx.dispatch('moveSection', {id, data, new_prj})
                }
            }
            if (obj.itemtype === 'section') {
                if (buffer.itemtype === 'section') {
                    let new_prj = obj.item.project
                    data.append('project', obj.item.project ? obj.item.project : '')
                    data.append('parent', obj.item.id)
                    ctx.dispatch('moveSection', {id, data, new_prj});
                }
                if (buffer.itemtype === 'document') {
                    let new_prj = obj.item.project
                    data.append('section', obj.item.id)
                    ctx.dispatch('moveDocument', {id, data, new_prj});
                }
            }
            ctx.commit('clearBuffer');
        },
    },
})