<template>
      <form class="add-button__input-container" v-on:submit.prevent="makeCallback">
        <input ref="addInput" type="text" v-model="name" class="add-button__input">
        <button ref="submit" class="add-button__input-confirm">
            <iconSvg name="checkmark"/>
        </button>
      </form>
</template>


<script>
export default {
    name: "ChangeNameInput",
    data: () => ({
        name: '',
    }),
    props: ['callback', 'item', 'itemName', 'project', 'callClose'],
    mounted() {
        this.$refs.addInput.focus();
        this.name = this.itemName ? this.itemName : '';
    },
    methods: {
        makeCallback() {
            if (this.project){
                this.callback(this.name, this.item.id, this.project);
            }
            else if (this.item && this.item.id) {
                this.callback(this.name, this.item.id);
            }
            else {
              this.callback(this.name);
            }
            this.callClose();
        },
    }
}

</script>

<style scoped>
.add-button__input-confirm {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  flex-shrink: 0;
  border: 0;
  display: block;
  background-color: var(--success);
  color: var(--light);
  cursor: pointer;
  border-radius: 5px;
}

.add-button__input-confirm:hover{
  background-color: var(--success-hover);
  color: var(--light-hover);
}

.add-button__input-container {
  z-index: 2;
  padding: 1px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  background-color: var(--light);
  display: flex;
  border-radius: 5px;
}

.add-button__input {
  margin-right: 1px;
  outline: none;
  border: 0px solid var(--secondary);
  height: 100%;
  width: 100%;
  min-height: 25px;
  display: block;
  color: var(--dark);
  padding-left: 15px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
}
</style>