<template>
    <div>
        <!-- test -->
    </div>
</template>

<script>
import {mapGetters} from "vuex"
export default {
  name: "Consumers",
    data() {
    return {
      host: 'localhost:9002',
    };
  },
  props: {
    room: String,
  },
  computed: {
      ...mapGetters(['Authorization',]),
  },
  methods: {
  connect() {
      this.chatSocket = new WebSocket(`ws:${this.host}/document/${this.room}/`, );
      this.chatSocket.onopen = () => {
        let check_zombie = JSON.stringify({
          action: 'check_zombie',
          is_editor: 'true',
        })
        this.chatSocket.send(check_zombie);
        this.status = "connected";
        console.log(this.status);
      },
      this.chatSocket.onmessage = (data) => {
        console.log(data);
        if (data.action == 'check_zombie') {
          console.log('check_zombie');
        }
      }
    
    },
   disconnect() {
      this.chatSocket.close();
      this.status = "disconnected";
      console.log(this.status);
    },

  },
  mounted() {
    // this.connect();
  },
  beforeDestroy() {
    // this.disconnect();
  },
}
</script>
