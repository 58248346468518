<template>
    <div>
   
        <permission-tool-action
            v-if="isShowToolAction"  
            @close="closeToolAction"
            :group="group"
            :group_or_user="group_or_user"
            :itemtype="itemtype"
            :item="item"
        />
        
        <li @click="showToolAction">
            <group-item :group="group"/>
        </li>
    </div>
</template>


<script>
import PermissionToolAction from './PermissionToolAction.vue';
import GroupItem from './GroupItem.vue';
export default {
  name: "PermissionGroup",
    data: () => ({
          isShowToolAction: false,
          group_or_user: 'group',
    }),
  methods: {
      showToolAction(){
          this.isShowToolAction = true;
      },
      closeToolAction(){
          this.isShowToolAction = false;
      }
  },
  props: {
      group: Object,
      itemtype: String,
      item: Object,
    },
    components: {
        PermissionToolAction,
        GroupItem,
    }
}
</script>


<style scoped>

li {
    list-style-type: none;
    background-color: var(--secondary-hover);
    cursor: pointer;
    padding: 15px;
}

li:hover {
    list-style-type: none;
    background-color: var(--secondary);
}

</style>