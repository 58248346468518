import axios from 'axios';
export default ({
    state: {
        section: [],
    },
    getters: {
        sectionList(state) {
            return state.section
        },
    },
    mutations: {
        addNewSection(state, section) {
            state.section.push(section);
        },
        removeSection(state, id) {
            state.section = state.section.filter(obj => obj.id !== id);
        },

    },
    actions: {
        addSection(ctx, props) {
            axios.post(`/api/section/`, props.data, {})
            .then((response) => {
                ctx.commit('addNewSection', response.data);
                ctx.dispatch('getProjectSections', props.proj_id);
            })
            .catch((err) => {
                console.log(err);
            })
        },
        
        deleteSection(ctx, props) {
            axios.delete(`/api/section/${props.id}/`, {})
            .then((response) => {
                if(response.status === 204) {
                    ctx.commit('removeSection', props.id);
                    ctx.dispatch('getProjectSections', props.proj_id);
                    this._vm.$toasted.show('раздел удален').goAway(2000); 
                }  
            })
            .catch((err) => {
                if (err.response.data.detail) {
                    this._vm.$toasted.error(err.response.data.detail).goAway(2500);
                }
                else {
                    console.log(err);
                }
            })
        },

        changeSection(ctx, props) {
            axios.put(`/api/section/${props.id}/`, props.data, {})
            .then(() => {
                ctx.dispatch('getProjectSections', props.proj_id);
            })
            .catch((err) => {
                console.log(err);
            })
        },

        moveSection(ctx, props) {
            axios.patch(`/api/section/${props.id}/`, props.data, {})
            .then(() => {
                ctx.dispatch('getProjectSections', props.new_prj);
                this._vm.$toasted.success('Раздел перемещен').goAway(2500);
            })
            .catch((err) => {
                console.log(err);
            })
        },
        
    },
})