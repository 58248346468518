<template>

<div>
    <div @click="showmenu=!showmenu" class='user-avatar'>
        <img :src="getUser.avatar" alt="">
    </div>
    <div @click="showmenu=false" v-if="showmenu" class='menu-wraper'>
    </div>
    <div @click="showmenu=false" v-if="showmenu" class='user-menu'>
        <router-link  v-if="jwt" class='user-menu-item' to="/">Главная</router-link>
        <router-link  v-if="jwt" class='user-menu-item' to="/users/">Пользователи</router-link>
        <router-link v-if="jwt" class='user-menu-item' to="/groups/">Группы</router-link>
        <div class='user-menu-item theme' @click="setTheme('light')" v-if="theme==='dark'">
            Cветлая тема <!--  <iconSvg name="sun"/> -->
        </div>
        <div class='user-menu-item theme' v-else @click="setTheme('dark')" >
            Tемная тема<!--  <iconSvg name="moon"/> -->
        </div>
        <div v-if="jwt" class='exit user-menu-item'  @click="logout()"> Выйти </div>
    </div>
</div>

</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ClickOutside from 'v-click-outside'
export default {
data() {
    return {
        showmenu: false,
    }
},
    directives: {
        ClickOutside: ClickOutside.directive
    },
  name: "UserMenu",

  computed: {
...mapGetters(["jwt", 'getUser', 'theme']),
  },
  methods: {
    ...mapActions(["fetchUser", 'jwtRemove', 'setTheme']),
    logout() {
      this.jwtRemove();
      this.$router.push({path: `/login/`});
    },
  },
  mounted() {
    if (this.jwt){ 
        this.fetchUser()
    }
    this.setTheme(this.theme);
  },
};
</script>


<style scoped>

img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid var(--reverse-hover);
  padding: 3px;
  background-color: transparent;
  transition: 0.2s;
  cursor: pointer;
}

img:hover {
    border: 2px solid var(--error);
}

.user-avatar {
    margin-right: 20px;
}

.menu-wraper {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.105);
    left: 0;
    top: 0;
}

.user-menu {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    position: absolute;
    right: 10px;
    background-color: var(--secondary-hover);
    line-height: 25px;
    text-transform: uppercase;
    box-shadow: 0em 0em 20px 1px rgb(0 0 0 / 25%);
    z-index: 1000;
    min-width: 250px;
    animation: showmenu 0.1s linear forwards;
    opacity: 0;
    border-radius: 10px;
    padding: 10px;
}

@keyframes showmenu {
    0% {
        top:-100px
    }
    100% {
        top: 10vh;
        opacity: 1;
    }
}
.user-menu-item, a, a:visited, a:hover {
    padding: 15px;
    color:var(--reverse);
    text-align: center;
    border-radius: 10px;
}

.user-menu-item:hover {
    background: var(--secondary);
    color:var(--reverse-hover);
}

.theme, .exit{
    cursor: pointer;
}

@media (max-width: 768px) {

.user-menu{
    width: 100%;
    top: 10vh;
    height: max-content;
    right: 0;
    margin: 0 auto;
    border-radius: 0px;
    

}
}
</style>
