<template>
    <div class="header">
        <router-link class="brand" to="/"> DOBRO DOC </router-link>
        <user-menu/>
    </div>
</template>
<script>
import UserMenu from "./UserMenu.vue";
export default {
  name: "LoginPage",
  components: {
    UserMenu,
  },
};
</script>


<style scoped>

.header {
  
  height: 10vh;
  background-color: var(--secondary);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--body-text);
}

.brand {
  font-family: "Proxima", Arial, sans-serif;
  margin-left: 20px;
  margin-right: 20px;
  font-size: 20px;
  cursor: pointer;
  color: var(--reverse);
}

.brand:hover {
  color: var(--reverse-hover);
}


@media (max-width: 768px) {

  .header {
    font-size: 20px;
  }

  .brand {
    margin-left: 10px;
    margin-right: 10px;
    font-size: 16px;
    cursor: pointer;
    color: var(--reverse);
  }
}
</style>