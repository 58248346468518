<template>
  <div>

    <div v-if="jwt" class="auth__form">
      <h1>Вы авторизованы</h1>
      <form v-on:submit.prevent="jwtRemove">
        <button ref="submit">Выйти</button>
      </form>
          <router-link to="/"><button class="link">перейти в DOBRO DOCS</button></router-link>
    </div>

    <div v-else class="auth__form">
      <h1>Авторизация</h1>
      <form v-on:submit.prevent="jwtCreate(authForm)">
        <input v-model="authForm.email" type="text" placeholder="Почта" />
        <input v-model="authForm.password" type="password" placeholder="Пароль"/>
        <button ref="submit">Войти</button>
      </form>
      <a :href="nextUrl"> <button class="link">Авторизация через почту @doboragency</button></a>
        <div class="error">{{ errorMsg }}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "LoginPage",
  data() {
    return {
      authForm: {
        email: "",
        password: "",
      },
    };
  },
  props: {},
  computed: {
    ...mapGetters(["jwt", "socialLink", "errorMsg"]),
    nextUrl() {
      if (!this.socialLink) {
        return this.socialLink
      }
      let url_social = new URL(this.socialLink);
      let redirect_uri = url_social.searchParams.get("redirect_uri");
      let url = new URL(window.location.href);
      let next = url.searchParams.get("next");
      if (next) {
        return url_social.href.replace(redirect_uri, `${redirect_uri}?next=${next}`);
      }
      return this.socialLink;
    }
  },
  methods: {
    ...mapActions(["jwtCreate", "jwtRemove", "getSocialLink"]),
  },
  mounted() {
    this.getSocialLink();
  },
};
</script>



<style scoped>
.auth__form,
form {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  width: 350px;
}

input,
input:-webkit-autofill {
  height: 50px;
  padding: 15px;
  margin-bottom: 10px;
  border: 1px solid var(--dark);
  outline: 0px;
  background-color: var(--light);
  -webkit-box-shadow: 0 0 0 30px var(--light) inset !important;
  border-radius: 15px;
  transition: 0.5s;
  font-size: 14px;
}

input:active, 
input:focus, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--light-hover) inset !important;

}
input::placeholder, textarea::placeholder {
  text-align: center;
}



button {
  height: 50px;
  background-color: var(--primary);
  color: var(--light-hover);
  border: 0;
  transition: 0.3s ease-out all;
  cursor: pointer;
  border-radius: 15px;
  font-size: 14px;
}

button:hover {
  background-color: var(--primary-hover);
  color: var(--light-hover);
}

h1 {
  align-self: center;
}

.error {
  color: var(--error);
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

button.link{
  background-color: var(--success);
  width: 100%;
  margin-top: 10px;
}

button.link:hover{
  background-color: var(--success-hover);
}

</style>
