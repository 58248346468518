import Vue from 'vue';
import VueRouter from 'vue-router';
import MainPage from '@/views/MainPage.vue';
import YandexAuth from '@/views/YandexAuth.vue';
import LoginPage from '@/views/LoginPage.vue';
import Users from '@/views/Users.vue';
import Groups from '@/views/Groups.vue';
import Group from '@/views/Group.vue';
import User from '@/views/User.vue';
import store  from '@/store';
Vue.use(VueRouter);


const routes = [
    {
        path: '',
        name: 'MainPage',
        component: MainPage,
        meta: { requiresAuth: true },
    },
    {
        path: '/auth/login/yandex/',
        name: 'YandexAuth',
        component: YandexAuth,
    },
    {
        path: '/login/',
        name: 'LoginPage',
        component: LoginPage,
    },
    {
        path: '/users/:id',
        name: 'User',
        component: User,
        meta: { requiresAuth: true },
    },
    {
        path: '/users/',
        name: 'Users',
        component: Users,
        meta: { requiresAuth: true },
    },
    {
        path: '/groups/:id',
        name: 'Group',
        component: Group,
        meta: { requiresAuth: true },
    },
    {
        path: '/groups/',
        name: 'Groups',
        component: Groups,
        meta: { requiresAuth: true },
    },
    {
        path: '/document/:id',
        name: 'MainPageDetail',
        component: MainPage,
    },
];


let router = new VueRouter({
    mode: 'history',
    base: "/",
    routes,
});
// setup router
export default router


router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (store.getters.isLoggedIn) {
            next();
            return;
        }
        next('/login/');
    } else {
        next();
    }
});
