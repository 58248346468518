<template>

    <div class="user__block">
      
            <div class='menu__button'>
            <router-link class='back__link' :to="{path: `/users/`}"> 
                <div><iconSvg name='arrow_left'/></div>
                <div>Назад</div>
            </router-link>
            </div>
        <div class="user__info">
            <div class="user__item">
                <h2>{{ user.first_name }} {{ user.last_name }}</h2>
                <div class="user__admin" v-if="user.is_superuser"> администратор</div>
                <div class="user__email">
                    {{ user.email }}
                </div>
            </div>
            <div class="user__item">
            <img class="user__avatar" :src="user.avatar" alt="" />
            </div>
        </div>  

        <div class="user__groups">Cостоит в группах: {{ groups }}</div>
    </div>

</template>

<script>
import axios from "axios";
export default {
  components: {},
  name: "User",
  data() {
    return {
      user: {},
    };
  },
  computed: {
      groups() {
          let list_group = [];
          for (let i in this.user.groups){
              list_group.push(this.user.groups[i].name)
          }
          return list_group.join(', ')
      }
  },
  methods: {
    fetchOneUser(id) {
      axios
        .get(`/auth/user/${id}/`, {})
        .then((response) => {
          this.user = response.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.fetchOneUser(this.$route.params.id);
    }
  },
};
</script>



<style scoped>


.menu__button {
  width: 100%;
  display: block;
  height: 40px;
  background-color: var(--army);
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
} 

.back__link {
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--reverse);
  padding: 10px;
}

.back__link>div {
    padding: 5px;
}

.user__info {
    display: flex;
    justify-content: space-between;
    padding: 30px;
}

.user__admin {
  color: var(--error);
}

.user__block {
  background-color: var(--secondary);
  width: 767px;
  margin: 0 auto;
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  min-height: 412;
  box-shadow: 0em 0em 20px 6px rgb(0 0 0 / 50%);
  border-radius: 20px;
}

.user__item {
    padding: 30px;
}

.user__groups {
    padding: 0px 60px 30px 60px;
    display: flex;
    align-items: center;
}

.user__email {
    padding: 10px 0;
    color: var(--body-text);
}

img {
  width: 200px;
  height: 200px;  
  background: transparent;
  border: 0px solid transparent;
}

@media (max-width: 768px) {
.user__block{
  width: 100%;
  margin: 0;
  height: 90vh;
  align-items: center;
  box-shadow: none;
}
.user__item {
  width: 100%;
  padding-top: 0;
  text-align: center;
  
}
.user__info{
  flex-wrap: wrap;

}
}

</style>
