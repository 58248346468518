<template>
        <div class='item'>
            <div class='iconSvg'>
                <iconSvg name="group"/>
            </div>
            <div>
                <span class="item">   {{group.name}}</span>
                <span class="detail">     Group     </span>
            </div>
        </div>
 
</template>


<script>
export default {
  name: "GroupItem",
  props: {
      group: Object,
  },
}
</script>


<style scoped>


.item {
    display: flex;
    align-items: center;
}

.iconSvg{
    height: 40px;
    width:  40px;
    align-items: center;
    margin-right: 20px;
    color: var(--reverse);
}

.detail {
    color: var(--body-text);
    font-size: 14px;
}


</style>