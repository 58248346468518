<template>
    <div ref='menu' class="additional-menu">
        <div v-if="canAddDocument" class="additional-menu__item"  @click="$emit('add-document')">
            <div >Добавить документ</div>
            <div class="additional-menu__item-icon">
                <iconSvg name="new_file"/>
            </div>      
        </div>
        <div v-if="canAddFolder" class="additional-menu__item"  @click="$emit('add-section')">
            <div >Добавить раздел</div>
            <div class="additional-menu__item-icon">
                <iconSvg name="new_folder"/>
            </div>      
        </div>
        <div v-if="canRename" class="additional-menu__item"  @click="$emit('rename');closeTools()">
            <div >Переименовать</div>
            <div class="additional-menu__item-icon">
                <iconSvg name="pensil"/>
            </div>      
        </div>
        <div v-if="canChangePermissions" class="additional-menu__item"  @click="$emit('permissions');closeTools()">
            <div > Настройка доступа </div>
            <div class="additional-menu__item-icon">
                <iconSvg name="key"/>
            </div>      
        </div>
        <div  v-if="canDelete" class="additional-menu__item" @click="deleteAction();closeTools()">
            <div >Удалить</div>
            <div class="additional-menu__item-icon">
                <iconSvg name="close"/>
            </div>      
        </div>
        <div  v-if="canMove" class="additional-menu__item" @click="$emit('move');closeTools()">
            <div >Переместить</div>
            <div class="additional-menu__item-icon">
                <!-- <iconSvg name="close"/> -->
            </div>      
        </div>
        <div  v-if="canPaste" class="additional-menu__item" @click="$emit('paste');closeTools()">
            <div >Вставить объект</div>
            <div class="additional-menu__item-icon">
                <!-- <iconSvg name="close"/> -->
            </div>      
        </div>

    </div>
</template>

<script>
import iconSvg from './iconSvg.vue'
import {mapGetters} from "vuex"
export default {
  components: { iconSvg },
  name: "Tools",
  props: {
      availableTools: Array,
      item: Object,
      itemtype: String,
      closeTools : Function
  },
  computed: {
    ...mapGetters(['getUser', 'buffer']),
    canRename() {
        if (this.availableTools.includes('rename')) {
            if(
                   this.item.group_is_editor > 0 
                || this.item.user_is_editor > 0
                || this.getUser.is_superuser
                || this.getUser.id === this.item.owner.id
            ) {
                return true;
            }
        }
        return false;
    },
    canDelete() {
        if (this.availableTools.includes('delete')){
            if(
                   this.getUser.is_superuser
                || this.getUser.id === this.item.owner.id
            ) {
                return true;
            }
        }
        return false;
    },
    canAddFolder() {
        if (this.availableTools.includes('folder')){
            if(this.item.depth > 3) {
                return false;
            }
            return true;
        }
        return false;
    },
    canAddDocument() {
        if (this.availableTools.includes('document')){
            return true;
        }
        return false;
    },
    canChangePermissions() {
        if (this.availableTools.includes('permission')){
            return this.canRename
        }
        return false;
    },

    canPaste() {
        if (!this.availableTools.includes('paste')){
            return false}
        if (!this.buffer || (this.buffer.itemtype === this.itemtype && this.buffer.item.id === this.item.id)){
            return false}
        if (this.buffer.itemtype === 'document' && this.itemtype === 'project') {
            return false}
        if (this.itemtype === 'section' && this.item.depth > 3){
            return false}
        return true

    },
    canMove() {
        if (this.availableTools.includes('move')) {
            return this.canRename
        }
        return false;
    },
  },
    mounted(){
        this.fitTool()
    },
    methods: {
      deleteAction() {
          let status = confirm("Удалить?");
          if(status) this.$emit('delete')
      },
      fitTool() {
            // метод для выравнивания меню, если оно выходит за границы.
            let menu = this.$refs.menu
            let box = menu.getBoundingClientRect();
            let left = box.left + pageXOffset;
            let bottom = box.bottom + pageYOffset;
            let height = document.documentElement.clientHeight;

            if( left < 0 ) {
                menu.style.transform = `translateX(${-left}px) `;
            }
            if( bottom > height ) {
                menu.style.transform = menu.style.transform += `translateY(${height - bottom - 10}px)`;
            }
        },
  }
}
</script>

<style>
    .additional-menu {
        background-color: var(--secondary-hover);
        position: absolute;
        top: 100%;
        right: 0;
        z-index: 2000;
        min-width: 240px;
        border-radius: 10px;
        box-shadow: 0em 0em 30px 2px rgb(0 0 0 / 30%);
        padding: 10px;
    }

    .additional-menu__item {
        border-radius: 10px;
        padding: 10px 15px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;
        color: var(--reverse);
        transition: 0.3s;
    }

    .additional-menu__item:hover {
        color: var(--reverse-hover);
        background-color: var(--secondary);
    }

    .additional-menu__item-icon {
        margin-left: auto;
    }
</style>