<template>
  <div class='window scroll'>
      <div class="user__block">
        <h2>Список пользователей</h2>
        <table>
          <tr @click="$router.push({path: `/users/${user.id}`})" class='user__item' v-for="user in getUsers" :key="user.id">
              <td class='tr_first'>   
                <img v-if="getUser.id===user.id" class='user__item__me' width="50px" :src="user.avatar" alt="">
                <img v-else width="50px" :src="user.avatar" alt="">
              </td>
              <td class='tr_middle'>
                 {{user.last_name}} {{user.first_name}} <span v-if="getUser.id===user.id">(это вы)</span>
              </td>
              <td class='tr_last'>
                {{user.email}}
              </td>
          </tr>
       </table>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
  },
  name: "Users",
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["getUsers", 'getUser']),
  },
  methods: {
    ...mapActions(["fetchUsers", 'fetchUser']),
  },
  mounted() {
    this.fetchUsers();
    this.fetchUser();
  },
};
</script>


<style scoped>

.window{
  height: 90vh;
  overflow: auto;
}

.user__block {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 30px;
}

h2 {
  margin: 0 auto;
  margin-bottom: 20px;
}

table {
  padding: 40px;
  margin: 0 auto;
  width: max-content;
  border-collapse: collapse;
  cursor: pointer;
}

.user__item__me{
  border-color: red;
}

img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid var(--reverse-hover);
  padding: 3px;
  background-color: transparent;
}

td {
  padding: 6px;
}

tr:hover {
  background-color: var(--body-bg);
  color: var(--reverse-hover);
}
 
.tr_first {
  width: 130px;
  padding-left: 30px;
}

.tr_middle {
width: 250px
}

.tr_last {
padding-right: 30px;
}


@media (max-width: 767px) {

  table {
    padding: 10px;
    margin: 0 auto;
    width: 100%;
    font-size: 12px;
  }

  .user__block {
    height: 100%;
    padding: 0px;
  }

  .tr_first {
    width: auto;
    padding-left: 5px;
  }

  .tr_middle {
  width: auto;
  }

  .tr_last {
  padding-right: 5px;
  }
}

</style>
