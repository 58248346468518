<template>
    <div>
     
        <permission-tool-action
            v-if="isShowToolAction"  
            @close="closeToolAction"
            :user="user"
            :group_or_user="group_or_user"
            :itemtype="itemtype"
            :item="item"
        />
        
        <li @click="showToolAction">
            <user-item :user="user"/>
        </li>
    </div>
</template>


<script>
import PermissionToolAction from './PermissionToolAction.vue';
import UserItem from './UserItem.vue';
export default {
    name: "PermissionUser",
    data: () => ({
        isShowToolAction: false,
        group_or_user: 'user',
    }),
    methods: {
        showToolAction(){
            this.isShowToolAction = true;
        },
        closeToolAction(){
            this.isShowToolAction = false;
        }
    },
    props: {
        user: Object,
        itemtype: String,
        item: Object,
    },
    components: {
        PermissionToolAction,
        UserItem,
    }
}
</script>


<style scoped>

li {
    list-style-type: none;
    background-color: var(--secondary-hover);
    cursor: pointer;
    padding: 15px;
}

li:hover {
    list-style-type: none;
    background-color: var(--secondary);
}


</style>