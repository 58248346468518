import axios from 'axios';
export default ({
    state: {
       user: {},
       users: [],
       groups: [],
       usersEditors: [],
       usersReaders: [],
       groupsEditors: [],
       groupsReaders: [],
    },
    getters: {
       getUser: (state) => state.user,
       getUsers: (state) => state.users,
       getGroups: (state) => state.groups,
       getUsersEditors: (state) => state.usersEditors,
       getUsersReaders: (state) => state.usersReaders,
       getGroupsEditors: (state) => state.groupsEditors,
       getGroupsReaders: (state) => state.groupsReaders,
    },
    mutations: {
        setUser(state, data){
            state.user = data;
        },
        setUsers(state, data){
            state.users = data;
        },
        setGroups(state, data){
            state.groups = data;
        },
        setUsersEditors(state, data){
            state.usersEditors = data;
        },
        setUsersReaders(state, data){
            state.usersReaders = data;
        },
        setGroupsEditors(state, data){
            state.groupsEditors = data;
        },
        setGroupsReaders(state, data){
            state.groupsReaders = data;
        },
        removeAllPermissions(state){
            state.groupsReaders = [];
            state.groupsEditors = [];
            state.usersReaders = [];
            state.usersEditors = [];
        }
    },
    actions: {
        fetchUser(ctx){
            axios.get(`/auth/user/me/`, {})
            .then((response) => {
                ctx.commit('setUser', response.data);
            })
            .catch((err) => {
                console.log(err);
            });
        },
        fetchUsers(ctx){
            axios.get(`/auth/user/`, {})
                .then((response) => {
                    ctx.commit('setUsers', response.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        fetchGroups(ctx){
            axios.get(`/auth/group/`, {})
                .then((response) => {
                    ctx.commit('setGroups', response.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        fetchUsersEditors(ctx, obj){
            axios.get(`/api/${obj.type}/${obj.id}/users_editors/`, {})
                .then((response) => {
                    ctx.commit('setUsersEditors', response.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        fetchUsersReaders(ctx, obj){
            axios.get(`/api/${obj.type}/${obj.id}/users_readers/`, {})
                .then((response) => {
                    ctx.commit('setUsersReaders', response.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        fetchGroupsEditors(ctx, obj){
            axios.get(`/api/${obj.type}/${obj.id}/groups_editors/`, {})
                .then((response) => {
                    ctx.commit('setGroupsEditors', response.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        fetchGroupsReaders(ctx, obj){
            axios.get(`/api/${obj.type}/${obj.id}/groups_readers/`, {})
                .then((response) => {
                    ctx.commit('setGroupsReaders', response.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        fetchAllow(ctx, obj) {
            const formData = new FormData();
            formData.append(obj.field, JSON.stringify(obj.recepient_list));
            axios.post(`/api/${obj.type}/${obj.id}/${obj.action}/`, formData, {})
                .then((response) => {
                    if (response.status < 400){ 
                        let param = {type: obj.type, id: obj.id};
                        
                        if (obj.action.includes('user')){
                            this.dispatch('fetchUsersEditors', param);
                            this.dispatch('fetchUsersReaders', param);
                        }
                        else if (obj.action.includes('group')){
                            this.dispatch('fetchGroupsEditors', param);
                            this.dispatch('fetchGroupsReaders', param);
                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        clearAllPermissions(ctx){
            ctx.commit('removeAllPermissions');
        }
    },
})