<template>
  <div id="app">
    <Header/>
    <router-view></router-view>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
export default {
  name: 'App',
  components: {
    Header
  }
}
</script>

<style>

@font-face {
	font-family: 'Proxima'; 
	src: url(https://static.tildacdn.com/tild3661-3561-4962-a663-346361383631/Pragmatica_Extended_.woff); 
}

:root {

  /* single colors */
  --light: rgb(193, 193, 193);
  --light-hover: #fff;
  --warning: rgb(220, 163, 20);
  --warning-hover: rgb(255, 184, 5);
  --error: red;
  --error-hover: rgb(252, 57, 57);
  --success: green;
  --success-hover: rgb(0, 164, 0);
  --primary: rgb(42, 56, 155);
  --primary-hover: rgb(54, 72, 205);
  --grey: #5e5e5e;
  --grey-hover: #555555;
  --dark: #1a1a1a;
  --dark-hover: #000000;
}

.dark {
  --table-background: rgb(13, 29, 31);
  --army: rgb(30, 78, 66);
  --reverse-hover: #fff;
  --reverse: rgb(193, 193, 193);
  --body-text: #acacac;
  --body-bg: #2e2e2e;
  --main-window: #1a1a1a;
  --section-hover: rgba(255,255,255,0.3);
  --secondary: #353535;
  --secondary-hover: #1a1a1a;
  --selected: #7b9aff;
}

.light {
  --table-background: rgb(234, 252, 254);
  --army: rgba(136, 196, 181);
  --reverse-hover: rgb(0, 0, 0);
  --reverse: rgb(62, 62, 62);
  --body-text: #343434;
  --body-bg: #b1b1b1;
  --main-window: #ffffff;
  --section-hover: rgba(255,255,255,0.3);
  --secondary: #e5e5e5;
  --secondary-hover: #ffffff;
  --selected: #2c4fc4;
}

body {
  margin: 0 auto;
  font-family: Arial, Helvetica, sans-serif;
  color: var(--body-text);
  background-color: var(--main-window);
  overflow: auto;
}


* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}


.scroll::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.scroll::-webkit-scrollbar-track {
  background: var(--secondary-hover);
}
.scroll::-webkit-scrollbar-thumb {
  background-color: var(--secondary);
  border: 2px solid var(--secondary-hover); 
}

svg {
  display: block;
}


a, a:visited{
    color: var(--reverse);
    text-decoration: none;
    transition: 0.1s;
}

a:hover{
  color: var(--reverse-hover);
}




@media (max-width: 600px) {
  .toasted-container .toasted {
      height: 10vh !important;
  }
}


</style>
