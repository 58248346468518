<template>
        <div class='item'>
            <img :src="user.avatar" alt="">
            <div>
                <span class="item">
                {{user.first_name}} {{user.last_name}}
                </span>
                <span class="detail">
                    {{user.email}} 
                </span>
            </div>
        </div>
 
</template>


<script>
export default {
  name: "UserItem",
  props: {
      user: Object,
  },
}
</script>


<style scoped>


.item {
    display: flex;
    align-items: center;
}

img {
    height: 40px;
    width:  40px;
    border-radius: 50%;
    margin-right: 20px;
}

.detail {
    color: var(--body-text);
    font-size: 14px;
}

</style>