<template>
  <div class='window scroll'>
      <div class="group__block">
        <h2>Список Групп</h2>
        <div class="group__item" v-for="group in getGroups" :key="group.id" >
          <router-link :to="{path: `/groups/${group.id}`}">
              <group-item :group="group"/>
          </router-link>
        </div> 
    </div>
</div>
</template>

<script>
import GroupItem from '../components/GroupItem.vue';
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Groups",
  data() {
    return {};
  },
  components: {   
    GroupItem,
  },
  computed: {
    ...mapGetters(["getGroups"]),
  },
  methods: {
    ...mapActions(["fetchGroups"]),
  },
  mounted() {
    this.fetchGroups();
  },
};
</script>


<style scoped>

.window{
  height: 90vh;
  overflow: auto;
}

.group__block {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 30px;
}

.group__item  {
  font-size: 20px;
  width: 500px;
  margin: 0 auto;
  
  display: flex;

}
h2 {
  margin: 0 auto;
  margin-bottom: 20px;
}

a {
  padding: 10px;
  cursor: pointer;
  width: 100%;
}
a:hover{
  background-color: var(--secondary);
}
.user__item__me {
  border-color: var(--error);
}

img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid var(--reverse-hover);
  padding: 3px;
  background-color: transparent;
}


@media (max-width: 767px) {


  .group__block {
    height: 100%;
    padding: 0px;
  }


}

</style>
