<template>

    <div class="content">
      
        <div class='button'>
        <router-link class='button__link' :to="{path: `/groups/`}"> 
            <div><iconSvg name='arrow_left'/></div>
            <div>Назад</div>
        </router-link>
        </div>
        <div class="group__block scroll">
         
                <h2>{{ group.name }} </h2>
                <div v-for="user in group.user_set" :key="user.id" class="group__user">
                  <user-item :user="user" />
                </div>
            
        </div>  
    </div>

</template>

<script>
import axios from "axios";
import UserItem from '../components/UserItem.vue';
export default {

  name: "Group",
  data() {
    return {
      group: {},
    };
  },
  components: {   
    UserItem,
  },
  methods: {
    fetchOneGroup(id) {
      axios
        .get(`/auth/group/${id}/`, {})
        .then((response) => {
          this.group = response.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.fetchOneGroup(this.$route.params.id);
    }
  },
};
</script>



<style scoped>


.button {
  width: 100%;
  display: block;
  height: 40px;
  background-color: var(--army);
    border-top-right-radius: 10px;
  border-top-left-radius: 10px;
} 

.button__link {
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--reverse);
  padding: 10px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.button__link>div {
  padding: 5px;

}

.group__block {
    padding: 30px;
    width: 100%;
    height: 600px;
    overflow: auto;
}


.content {
  background-color: var(--secondary);
  width: 450px;
  margin: 0 auto;
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  min-height: 412;
  box-shadow: 0em 0em 20px 6px rgb(0 0 0 / 50%);
  border-radius: 10px;
}



.group__user {
    padding: 10px 0;
    color: var(--body-text);
}


@media (max-width: 768px) {
  .content{
    width: 100%;
    margin: 0;
    height: 90vh;
    align-items: center;
    box-shadow: none;
  }

  .group__block{
    flex-wrap: wrap;
  }
}

</style>
