<template>
  <ul>
    <permissionTool
      v-if="permissiontool"
      :item="item"
      :itemtype="itemtype"
      v-click-outside="closePermissionTool"
      @close="closePermissionTool"
    />

    <li class="project" v-if="item" @mouseleave="hide()">
      <div
        class="project__head"
        v-if="!edit"
        :class="{ hovered: tools }"
        @contextmenu.prevent="openTools()"
      >
       <div @click.stop="fetchProjectSections(item.id)" class="project__name">
          <div :class="{down :subsection && item.id === projectSectionsList.id}">
            <iconSvg name="arrow_down" />
          </div>
          {{item.name}} 
        </div>
        <div class="loading__circle" v-if="loading">
          <IconSvg name="loading"/>
        </div>
        <div class="project__tools" v-if="availableTools">
          <div class="project__menu" @click="openTools()">
            <iconSvg name="more" />
          </div>
          <tools
            v-if="tools"
            v-click-outside="hide"
            :availableTools="availableTools"
            :item="item"
            :itemtype="itemtype"
            :closeTools="closeTools"
            @add-section="newSection()"
            @permissions="openPermissionTool(item.id, itemtype)"
            @delete="deleteProject(item.id)"
            @rename="rename(item.name)"
            @paste="pasteObject({item, itemtype})"
          />
        </div>
      </div>

      <!-- для переименования -->
      <changeNameInput
        v-else
        v-click-outside="hideInput"
        :callback="changeProjectName"
        :itemName="item.name"
        :item="item"
        :callClose="hideInput"
      />

      <!-- для добавления нового -->
      <changeNameInput
        v-if="type==='section'"
        v-click-outside="hideInput"
        :callback="addNewSection"
        :callClose="hideInput"
      />

      <div v-show="subsection" v-if="item.id === projectSectionsList.id">
        <div v-if="projectSectionsList.sections.length > 0">
          <menuSection
            v-for="section in projectSectionsList.sections"
            :key="section.id"
            :item="section"
            :callbackShow="showSubsections"
          />
        </div>
        <ul v-else>
          <li class="section">
            <div  class="project__head">
              <div  class="project__name">
                Пусто
              </div>
            </div>
          </li>
        </ul>
      </div>
    </li>
  </ul>
</template>

<script>
import ClickOutside from "v-click-outside";
import { mapActions, mapGetters } from "vuex";
import MenuSection from "./menuSection.vue";
import Tools from "./Tools.vue";
import PermissionTool from "./PermissionTool.vue";
import ChangeNameInput from "./ChangeNameInput.vue";
import IconSvg from "./iconSvg.vue";
export default {
  name: "menuProject",
  data: () => ({
    edit: false,
    name: "",
    tools: false,
    type: null,
    permissiontool: false,
    itemtype: "project",
    subsection: false,
    loading: false,
  }),
  props: {
    item: Object,
  },
  components: {
    ChangeNameInput,
    MenuSection,
    Tools,
    IconSvg,
    PermissionTool,
  },
  directives: {
    ClickOutside: ClickOutside.directive,
  },
  computed: {
    ...mapGetters(["projectSectionsList", "getUser"]),
    availableTools() {
      return ["rename", "delete", "folder", "permission", 'paste'];
    },
  },
  watch: {
    projectSectionsList: function () {
      if (this.projectSectionsList) {
        this.loading = false;
      }
    }
  },
  methods: {
    ...mapActions([
      "deleteProject",
      "changeProject",
      "getProject",
      "getProjectSections",
      "addSection",
      'pasteObject'
    ]),
    showSubsections() {
      this.subsection = true;
    },
    fetchProjectSections(id) {
      let project_id = this.projectSectionsList.id
      if (!project_id || project_id !== id) {
        this.loading = true;
        this.getProjectSections(id);
        this.subsection = true;
      }
      else {
        this.subsection = !this.subsection;
      }
    },
    hide(e = 1) {
      if(!e) return
      this.tools = false;
    },
    hideInput(e = 1) {
      if(!e) return
      this.edit = false;
      this.type = null;
    },
    rename(text) {
      this.edit = true;
      this.name = text;
      this.hide();
    },
    newSection() {
      this.type = "section";
      this.hide();
    },

    async addNewSection(name) {
      if (!name) return;
      let form_data = new FormData();
      form_data.append("title", name);
      form_data.append("project", this.item.id);
      await this.addSection({data: form_data, proj_id: this.item.id});
    },
    openPermissionTool() {
      this.permissiontool = true;
    },
    closePermissionTool() {
      this.permissiontool = false;
    },
    async changeProjectName(name, id) {
      if (!name || !id) return;
      let form_data = new FormData();
      form_data.append("name", name);
      await this.changeProject({ data: form_data, id: id });
      this.edit = false;
      this.hide();
    },
    openTools() {
      this.tools = true;
    },
    closeTools(){
      this.tools = false;
    },
  },
};
</script>

<style scoped>

.loading__circle svg{
    stroke-dasharray: 100;
    stroke-dashoffset: 100;
    animation: dash 1s linear infinite;
}

@keyframes dash {
    to {
      stroke-dashoffset: 0;
      transform: rotate(720deg);
    }
}

ul {
  padding-left: 30px;
}
li > ul {
  border-left: 1px solid var(--reverse-hover);
}
li {
  font-size: 18px;
}

li > ul > li {
  font-size: 16px;
}

li > ul > li > ul > li {
  font-size: 14px;
}

li {
  list-style-type: none;
}

.project {
  cursor: pointer;
}

.project__head {
  padding: 5px;
  padding-left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.3s;
  background-color: transparent;
}

.project__head:hover {
  background-color: var(--section-hover);
}

.project__head.hovered {
  background-color: var(--section-hover);
}

.project__head:hover .project__tools {
  opacity: 1;
}

.project__head.hovered .project__tools {
  opacity: 1;
}

.project__name {
  display: flex;
  align-items: center;
}

.project__name div {
  margin-right: 5px;
  transition: 0.1s;
  transform: rotate(-90deg);
}

.project__name div.down {
  transform: rotate(0deg);
}

.project__tools {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.project__tools * {
  margin-left: 10px;
}

.project__tools:first-child {
  margin-left: 0;
}

.project__tools > .project__menu {
  opacity: 0;
  cursor: pointer;
  transition: 0.3s;
}

.project .project__head:hover .project__menu {
  opacity: 0.7;
}

.project__tools > .project__menu:hover {
  opacity: 1;
  color: var(--reverse-hover);
}

.project .project__head.hovered .project__menu {
  opacity: 1;
}


.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
</style>