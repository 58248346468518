<template>
  <ul>
    <permissionTool
      v-if="permissiontool"
      :item="item"
      :itemtype="itemtype"
      v-click-outside="closePermissionTool"
      @close="closePermissionTool"
    />

    <li class="section" v-if="item" @mouseleave="hide()">
      <div
        class="project__head"
        v-if="!edit"
        :class="{ hovered: tools }"
        @contextmenu.prevent="openTools()"
      >
        <div @click="toggleSections()" class="project__name">
          <div :class="{down :subsection}" v-if="hasSubsection">
            <iconSvg name="arrow_down" />
          </div>
          {{item.title}}
        </div>
        <div class="project__tools" v-if="availableTools">
          <div class="project__menu" @click="openTools()">
            <iconSvg name="more" />
          </div>
          <tools
            v-if="tools"
            v-click-outside="hide"
            :availableTools="availableTools"
            :item="item"
            :itemtype="itemtype"
            :closeTools="closeTools"
            @permissions="openPermissionTool(item.id, itemtype)"
            @add-document="newDocument()"
            @add-section="newSection()"
            @delete="deleteOneSection(item.id)"
            @rename="rename(item.title)"
            @move="moveObject({item, itemtype})"
            @paste="pasteObject({item, itemtype})"
          />
        </div>
      </div>

      <!-- для переименования -->
      <changeNameInput
        v-else
        v-click-outside="hideInput"
        :callback="changeSectionName"
        :itemName="item.title"
        :item="item"
        :callClose="hideInput"
      />

      <!-- для добавления нового -->
      <changeNameInput
        v-if="type"
        v-click-outside="hideInput"
        :callback="type === 'section' ? addNewSection : addNewDocument"
        :item="item"
        :project="type === 'section' ? item.project : null"
        :callClose="hideInput"
      />

      <menuDocument
        v-show="subsection"
        v-for="document in item.documents"
        :key="document.id"
        :item="document"
        :callbackShow="showSubsections"
      />
      
      <menuSection
        v-show="subsection"
        v-for="section in item.children"
        :key="section.id"
        :item="section"
        :callbackShow="showSubsections"
      />
      
      
    </li>
  </ul>
</template>

<script>
import PermissionTool from "./PermissionTool.vue";
import ClickOutside from "v-click-outside";
import { mapActions, mapGetters } from "vuex";
import MenuDocument from "./menuDocument.vue";
import MenuSection from "./menuSection.vue";
import ChangeNameInput from "./ChangeNameInput.vue";
import Tools from "./Tools.vue";
export default {
  name: "menuSection",
  data: () => ({
    edit: false,
    tools: false,
    name: "",
    type: null,
    permissiontool: false,
    itemtype: "section",
    subsection: false,
    defaultDocument: {
      time: 1629209706671,
      blocks: [{ type: "header", data: { text: "Новый Документ", level: 2 } }],
      version: "2.20.0",
    },
  }),
  props: {
    item: Object,
    callbackShow: Function,
  },
  components: {
    MenuDocument,
    MenuSection,
    Tools,
    PermissionTool,
    ChangeNameInput,
  },
  directives: {
    ClickOutside: ClickOutside.directive,
  },
  computed: {
    ...mapGetters(["getUser"]),
    availableTools() {
      return ["rename", "delete", "folder", "document", "permission", 'move', 'paste'];
    },
    hasSubsection(){
      return (
        this.item && 
        (this.item.children.length > 0 
        || this.item.documents.length > 0))
    },
  },
  methods: {
    ...mapActions([
      "addSection",
      "addDocument",
      "deleteSection",
      "changeSection",
      "getProject",
      "getProjectSections",
      'moveObject',
      'pasteObject',
    ]),
    toggleSections() {
      this.subsection = !this.subsection;
    },
    showSubsections() {
      this.subsection = true;
      this.callbackShow()
    },
    hide(e = 1) {
      if(!e) return
      this.tools = false;
    },
    hideInput(e = 1) {
      if(!e) return
      this.edit = false;
      this.type = null;
    },
    rename(text) {
      this.edit = true;
      this.name = text;
    },
    openTools() {
      this.tools = true;
    },
    closeTools(){
      this.tools = false;
    },
    newDocument() {
      this.type = "document";
      this.hide();
    },
    newSection() {
      this.type = "section";
      this.hide();
    },
    openPermissionTool() {
      this.permissiontool = true;
    },
    closePermissionTool() {
      this.permissiontool = false;
    },
    async changeSectionName(name, id) {
      if (!name || !id) return;
      let form_data = new FormData();
      form_data.append("title", name);
      await this.changeSection({ data: form_data, id: id, proj_id: this.item.project }).then(() => {
        this.edit = false;
        this.hide();
      });
    },
    async deleteOneSection(id) {
      if (!id) return;
      const props = {id: id, proj_id: this.item.project}
      await this.deleteSection(props);
    },
    async addNewSection(name, parent, project_id) {
      if (!name) return;
      let form_data = new FormData();
      form_data.append("title", name);
      form_data.append("project", project_id);
      form_data.append("parent", parent);
       await this.addSection({data: form_data, proj_id: this.item.project});
      this.hideInput();
    },
    async addNewDocument(name, id) {
      if (!name) return;
      let form_data = new FormData();
      form_data.append("editor_json", JSON.stringify(this.defaultDocument));
      form_data.append("section", id);
      form_data.append("title", name);
      await this.addDocument({data: form_data, proj_id: this.item.project});
      this.hideInput();
    },
  },
};
</script>

<style scoped>
ul {
  padding-left: 30px;
}
li > ul {
  border-left: 1px solid var(--reverse-hover);
}
li {
  font-size: 18px;
}

li > ul > li {
  font-size: 16px;
}

li > ul > li > ul > li {
  font-size: 14px;
}

li {
  list-style-type: none;
}

.project__name {
  display: flex;
  align-items: center;
}

.project__name div {
  margin-right: 5px;
  transition: 0.1s;
  transform: rotate(-90deg);
}

.project__name div.down {
  transform: rotate(0deg);
}

.project__head {
  padding: 5px;
  padding-left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.3s;
  background-color: transparent;
}

.project__head:hover {
  background-color: var(--section-hover);
}

.project__head.hovered {
  background-color: var(--section-hover);
}

.project__head:hover .project__tools {
  opacity: 1;
}

.project__head.hovered .project__tools {
  opacity: 1;
}

.project__tools {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.project__tools * {
  margin-left: 10px;
}

.project__tools:first-child {
  margin-left: 0;
}

.project__tools > .project__menu {
  opacity: 0;
  cursor: pointer;
  transition: 0.3s;
}

.project .project__head:hover .project__menu {
  opacity: 0.7;
}

.project__tools > .project__menu:hover {
  opacity: 1;
  color: var(--reverse-hover);
}

.project .project__head.hovered .project__menu {
  opacity: 1;
}

.add-button__input-confirm {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  flex-shrink: 0;
  border: 0;
  display: block;
  background-color: var(--success);
  color: var(--light);
  cursor: pointer;
  border-radius: 5px;
}

.add-button__input-confirm:hover {
  background-color: var(--success-hover);
  color: var(--light-hover);
}

.add-button__input-container {
  z-index: 2;
  padding: 1px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  background-color: var(--light);
  display: flex;
  border-radius: 5px;
}

.add-button__input {
  margin-right: 1px;
  outline: none;
  border: 0px solid var(--secondary);
  height: 100%;
  width: 100%;
  min-height: 25px;
  display: block;
  color: var(--dark);
  padding-left: 15px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
</style>